import apiClient from './config'
import store from './../store'

if (store.state.user) {
    apiClient.defaults.headers.common['Authorization'] = store.state.user.token
}

export default {
    login(data) {
        return apiClient.post('/users/login', data)
    },

    register(data) {
        return apiClient.post('/users/register', data)
    },

    passwordRequest(data) {
        return apiClient.post('/users/password_request', data)
    },

    passwordReset(data) {
        return apiClient.post('/users/password_reset', data)
    },

    send(data) {
        return apiClient.post('/users/contact', data)
    },

    all(data) {
        return apiClient.post('/users', data)
    },

    find(data) {
        return apiClient.post('/users/find', data)
    },

    create(data) {
        return apiClient.post('/users/create', data)
    },

    update(data) {
        return apiClient.post('/users/update', data)
    },
}
