<template>
    <v-container>
        <v-row>
            <v-col cols="6" offset="3">

                <v-form @submit.prevent="send" ref="questionForm">
                    <v-card>
                        <v-card-title>
                            {{ $t('contact') }}
                        </v-card-title>

                        <v-card-text>
                            <div>{{ base.name }}</div>
                            <div>{{ base.address }}</div>
                            <div>{{ base.postcode }} {{ base.city }}</div>
                            <div>{{ base.telephone }}</div>
                            <div><a :href="emailString">{{ base.email }}</a></div>

                            <v-divider class="mt-4 mb-4"></v-divider>

                            <div>{{ $t('my_question_msg_1') }}</div>
                            <div>{{ $t('my_question_msg_2') }}</div>

                            <v-divider class="mt-4 mb-4"></v-divider>

                            <v-textarea id="questionField"
                                        v-model="questionDetails.question"
                                        :label="$t('my_question')"
                                        :rules="questionRules"
                                        required
                            ></v-textarea>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn id="sendButton" type="submit" color="info">
                                <v-icon dark left>mdi-send</v-icon>
                                {{ $t('send') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>

        <v-snackbar id="snackbar" :color=snackbarColor :top=snackbarTop v-model="snackbar">
            {{ snackbarMessage }}

            <v-btn text @click="snackbar=false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
    import {mapState} from 'vuex'
    import userService from '@/services/UserService.js'

    export default {
        name: "Contact",

        computed: {
            ...mapState(['base', 'isLoggedIn', 'user']),

            emailString() {
                return "mailto:" + this.base.email
            },
        },

        created() {
            // Route guard
            if (!this.isLoggedIn) {
                this.$store.dispatch('logout')
                this.$router.push('/login')
            }
        },

        data() {
            return {
                questionDetails: {
                    question: '',
                    user_name: '',
                    user_email: '',
                },

                questionRules: [
                    value => !!value || this.$t('required'),
                ],

                snackbar: false,
                snackbarTop: true,
                snackbarColor: '',
                snackbarMessage: '',
            }
        },

        methods: {
            send() {
                if (this.$refs.questionForm.validate()) {
                    this.dialog = false

                    this.questionDetails.user_name = this.user.name
                    this.questionDetails.user_email = this.user.email

                    userService.send(this.questionDetails)
                        .then(() => {
                            this.snackbar = true
                            this.snackbarColor = "success"
                            this.snackbarMessage = this.$t('data_saved')
                        })
                        .catch(() => {
                            this.snackbar = true
                            this.snackbarColor = "error"
                            this.snackbarMessage = this.$t('invalid_data')
                        })
                }
            },
        },
    }
</script>
